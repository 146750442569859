import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'100%'}
    viewBox="0 0 166 166"
    fill="none"
    {...props}
  >
    <circle cx={83} cy={83} r={83} fill="#fff" fillOpacity={0.2} />
    <path
      fill="#fff"
      d="m85.878 106-3.5-3.455 14.409-14.409H59.469v-5h37.318l-14.41-14.363 3.5-3.5 20.364 20.363L85.878 106Z"
    />
  </svg>
)
export default SvgComponent
