import RightArrowSvg from '../../svgs/RightArrow';

const data=[{},{},{}]

export default function Component(props){

    return(
        <div className='relative w-full flex flex-col text-black'>
            <div className="relative w-full flex flex-col items-center justify-center d-px100-m-px24">
                <div className='max-w-[1200px] w-full flex flex-col py-[200px]'>
                    
                    {/*Title Bar*/}
                    <div className='flex d-row-m-col w-full justify-between items-start mb-[50px]'>
                        <div className='flex flex-row items-center'>
                            <div className='flex flex-col'>
                                <div className='text-[36px]'>News</div>
                                <div className='text-[12px]'>Explore all the resources on our platform at your leisure.</div>
                            </div>
                        </div>
                        <div className='flex flex-row items-center pb-[8px] cursor-pointer hover:font-bold mt-[24px]' style={{borderBottom:'1px solid black'}}>
                            <div className='text-[16px] mr-[24px]'>View all</div>
                            <RightArrowSvg/>
                        </div>
                    </div>
                    {/*Title Bar*/}


                    {/*data*/}
                    <div style={{width:'100%', height:.5,backgroundColor:"black"}}></div>
                    {data.map((item,index)=>
                        <div key={index} className='w-full cursor-pointer flex flex-row items-center justify-start py-[24px] hover:opacity-[7%]' style={{borderTop:'.5px solid black',borderBottom:'.5px solid black'}}>
                            <div className='text-[10px] mr-[50px]'>2024.06.01</div>
                            <div className='text-[16px]'>Discover More Collections </div>
                        </div>
                    )}
                    <div style={{width:'100%', height:.5,backgroundColor:"black"}}></div>

                </div>
            </div>

        </div>
    )
}
