import { useLocation, useNavigate } from 'react-router-dom';
import { RESOURCES } from '../../config/archives.config';
import TopLeftCurve from '../../svgs/TopLeftCurve';
import TopRightCurve from '../../svgs/TopRightCurve';
export default function Component(props) {
    const navigate = useNavigate();
    const location = useLocation()
    const navigating=(str)=>()=>{
      navigate(str)
    }
    return (
      <div className='relative w-full flex flex-col text-black'>
        <TopLeftCurve className='absolute top-0 left-0'/>
        <TopRightCurve className='absolute top-0 right-0'/>
        <div className="relative w-full flex flex-col items-center justify-start d-px100-m-px24 min-h-[100vh]">
            <div className='flex flex-wrap flex-row w-full justify-start max-w-[1200px] gap-[3.3%] pt-[64px]'>
              {RESOURCES.map((item,index)=>
                <div className='d-w-30-m-100 flex flex-col cursor-pointer mb-[36px]' style={{}} key={index} onClick={navigating(`/resources/${item?.id}`)}>
                  <img src={item?.preview} style={{minWidth:'100%',height:undefined,aspectRatio:344/194,objectFit:'cover'}} alt='archive'/>
                  <div className='text-[14px] font-bold'>{item?.title}</div>
                </div>
              )}
            </div>
        </div>
      </div>
    );
  }
  
  