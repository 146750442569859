import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    fill="none"
    {...props}
  >
    <path
      fill="#D6DBE5"
      d="M100 0H0v100s1.37-43.644 31.05-71.186C60.73 1.27 100 0 100 0Z"
    />
  </svg>
)
export default SvgComponent
