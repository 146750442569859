import TopLeftCurve from '../../svgs/TopLeftCurve';
import TopRightCurve from '../../svgs/TopRightCurve';
export default function Component(props) {
    return (
      <div className='relative w-full flex flex-col text-black'>
        <TopLeftCurve className='absolute top-0 left-0'/>
        <TopRightCurve className='absolute top-0 right-0'/>
        <div className="relative w-full flex flex-col items-center justify-center d-px100-m-px24 min-h-[100vh]">
            
        </div>
      </div>
    );
  }
  
  