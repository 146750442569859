import DetailsButtonSvg2 from '../svgs/DetailsButtonSvg2';
import { useNavigate, useLocation } from "react-router-dom";

export default function Component(props){
    const navigate = useNavigate();
    const location = useLocation()
    const navigating=(str)=>()=>{
      navigate(str)
    }
    return(
        <div className='relative w-full flex flex-col'>
            <div className='relative w-full' style={{height:undefined,aspectRatio:3840/1250}}>
                {/* <img src='/banner1.png' className='w-full' alt='banner'/> */}
                <div className='relative w-full h-full' style={{height:undefined,aspectRatio:3840/1250,overflow:'hidden'}}>
                    <img src='/parallax.png' className='w-full absolute' style={{transition:'.1s all ease',left:0,top: `calc( ${-props.srcollPostion-(props.offset||0)  } * 100vw / 3840 * ${2456-1250} )` }} alt='banner'/>
                </div>
                <div className='absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center d-px100-m-px24'>
                    <div className='w-full max-w-[1200px] flex flex-col text-white'>
                        <div className='font-32 w-[70%]'> Unlocking Thousands of <br/>Art Education Resources</div>
                        <div className='mt-[12px] font-16 w-[70%]'>Welcome to the Digital Arts Education Platform of the Hong Kong Arts Festival! We are delighted to share a broad spectrum of arts educational materials on this new platform. We hope that you will find the resources interesting, </div>
                    </div>
                </div>
                <div className='absolute bottom-[42%] right-[20%] w-[7%] hover:opacity-[70%] cursor-pointer' onClick={navigating('/register')}>
                    <DetailsButtonSvg2/>
                </div>
            </div>
        </div>
    )
}
