import '../../screens/register.css'
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PostSurvey } from '../../services/survey.server';
import { useCookies } from 'react-cookie';
import { COOKIESPREFIX } from '../../config';
function Screen(props) {
    const [cookies, setCookie, removeCookie] = useCookies([`@${COOKIESPREFIX}_gensurvey`]);
    const [data, setData] = useState({purpose: {}, usertype: 'general'})
    const [step, setStep] = useState(1)
    const [isMissing, setIsMissing] = useState(false)
    function handlePurposeAdd(type, bol){
        const newPur = {...data.purpose}
        if(bol)newPur[type] = bol
        if(!bol) delete newPur[type]
        setData(prev => {return{...prev, 'purpose': newPur}})
    }
    async function handleMoveStep(num){
        if(num === 1){
            if(!data.role)return setIsMissing(true);
            if(Object.keys(data.purpose).length === 0)return setIsMissing(true);
            setStep(num+1)
            setIsMissing(false)
        }
        if(num === 2){
            const res = await PostSurvey(data)
            if(res.errno)return;
            setCookie(`@${COOKIESPREFIX}_gensurvey`, true)
            props.handleSetPopSurvery(false)
        }
    }
    useEffect(() => {
        // console.log(data)
    },[data])
    return (
        <div className='fixed inset-0 w-full h-full flex items-center justify-center bg-[#00000066]'>
            <div className='w-full max-w-[940px] min-h-[489px] bg-white rounded-xl px-[64px] py-[23px] font-bold'>
                <div className='font-18-12 text-[#0014CA]'>Please help us understand you more :</div>
                {step === 1 && <>
                    <div className='w-full min-h-[400px] flex flex-1 flex-col justify-between gap-2 mt-[24px]'>
                        <div>
                            <div className='w-full three-card-flex items-between justify-center gap-2'>
                                <div className='flex-1 font-14-10'>Q1. What is your current role in education? *</div>
                                <div className='cursor-pointer h-full flex-1'>
                                    <div className='group/program relative h-full border border-grey'>
                                        <div className='flex flex-1 items-center gap-1 h-full w-full'>
                                            <div className='mx-[12px]' style={{color: data.role ? 'black' : '#989898'}}>{data.role || 'please select'}</div>
                                        </div>
                                        <div className={'flex flex-col w-[280px] absolute rounded-b-xl py-[4px] px-[17px] invisible group-hover/program:visible bg-white border border-grey'}>
                                            {['Teacher', 'Student', 'Administrator', 'Artist', 'Supporter', 'Parent', 'Other (Please Specify)'].map((role) => (
                                                <div onClick={() => setData(prev => {return {...prev, role}})} key={role} className='w-full border-b border-grey'>
                                                    <div>{role}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {data?.role?.split(' ')[0] === 'Other' && <div className='mt-[12px]'>
                                <div className='w-full three-card-flex items-between justify-center gap-2'>
                                    <div className='flex-1 font-14-10'>Please specify your role. *</div>
                                    <input onChange={(e) => setData(prev => {return {...prev, otherRole:e.target.value}})} className='flex-1 font-14-10 border border-grey outline-none'></input>
                                </div>
                            </div>}
                            <div className='mt-[59px]'>
                                <div className='font-14-10'>Q2. What is your main purpose of visiting? (Select all that apply)  *</div>
                                <div className='three-card-flex w-full flex-wrap text-nowrap mt-[20px] gap-2'>
                                    {['Learning new skills','Enhancing existing knowledge', 'Teaching (primary)', 'Teaching (secondary)', 'Teaching (tertiary)', 'Research purposes', 'Reach out for collaboration', 'Other (please specify)'].map((type) => {
                                    if(type  === 'Other (please specify)')return(
                                        <div key={type} className='w-full flex flex-wrap'>
                                            <div>
                                                <input className='mr-[4px] cursor-pointer' type='checkbox'></input>
                                                <label className='warp-none font-14-10 mr-[4px]'>{type}</label>
                                            </div>
                                            <input onChange={(e) => handlePurposeAdd('other', e.target.value)} placeholder='please specify' className='cursor-pointer border border-grey flex flex-1 min-w-[194px]'></input>
                                        </div>)
                                    return (
                                        <div key={type} className='w-[32%]'>
                                            <input onChange={(e) => handlePurposeAdd(type, e.target.checked)} className='mr-[4px] cursor-pointer' type='checkbox'></input>
                                            <label className='w-full warp-none font-14-10'>{type}</label>
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-center w-full'>
                            {isMissing && <div className='text-[red]'>Please fill in the survey.</div>}
                            <div onClick={() => handleMoveStep(1)}className='select-none bg-[#0050C8] w-full max-w-[409px] flex-1 rounded-full text-center text-white py-[18px] font-bold cursor-pointer'>Next</div>
                        </div>
                    </div>
                </>}
                {step===2 &&<>
                    <div className='w-full min-h-[400px] flex flex-1 flex-col justify-between gap-2 mt-[24px]'>
                        <div className='w-full flex-1 flex flex-col'>
                            <div className='font-14-10'>Q3. Please provide your contact details:  </div>
                            <input onChange={(e) => setData(prev => {return{...prev, name: e.target.value}})} placeholder='Name' className='border-b py-[10px] font-14-10 flex outline-none'></input>
                            <input onChange={(e) => setData(prev => {return{...prev, email: e.target.value}})} placeholder='Email Address' className='border-b py-[10px] font-14-10 flex outline-none'></input>
                        </div>
                        <div className='flex three-card-flex items-center w-full mt-auto gap-2'>
                            <div onClick={() => handleMoveStep(2)}className='select-none bg-[#558ADB] w-full max-w-[409px] flex-1 rounded-full text-center text-white py-[18px] font-bold cursor-pointer'>skip</div>
                            <div onClick={() => handleMoveStep(2)}className='select-none bg-[#0050C8] w-full max-w-[409px] flex-1 rounded-full text-center text-white py-[18px] font-bold cursor-pointer'>Submit</div>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
  })(Screen)
  