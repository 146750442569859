import TopLeftTriangleSvg from '../../svgs/TopLeftTriangle';
import RightArrowSvg from '../../svgs/RightArrow';
import DetailsButtonSvg from '../../svgs/DetailsButtonSvg';
import { useNavigate, useLocation } from "react-router-dom";

export default function Component(props){
    const navigate = useNavigate();
    const location = useLocation()
    const navigating=(str)=>()=>{
      navigate(str)
    }

    return(
        <div className='relative w-full flex flex-col'>
            <div className="relative w-full flex flex-col items-center justify-end text-[white] d-px100-m-px24 text-black">
                <div className='max-w-[1200px] w-full flex flex-col mt-[200px] mb-[250px]'>

                    <div className='w-full flex flex-col items-center justify-center mb-[150px]'>
                        <div className='text-[20px] font-bold'>Welcome to the Digital Arts Education Platform by the Hong Kong Arts Festival, </div>
                        <div className='text-[16px] text-center'>where a rich tapestry of arts educational resources awaits your discovery. Our platform is dedicated to making art accessible to all, whether you are a teacher aiming to cultivate students' aesthetic experiences, an artist kin to offer artistic practice for education, or a passionate supporter of the arts in Hong Kong. Let this platform support our learning, appreciation and exploration in the arts together.</div>
                    </div>



                    <div className='flex d-row-m-col items-start w-full justify-between'>
                        <div className='flex flex-row items-start'>
                            <TopLeftTriangleSvg className='mt-[13px]'/>
                            <div className='flex flex-col ml-[8px]'>
                                <div className='text-[36px]'>Resources For</div>
                                <div className='text-[12px]'> Explore all the resources on our platform at your leisure.</div>
                            </div>
                        </div>
                        <div className='flex flex-row items-center pb-[8px] cursor-pointer hover:font-bold mt-[24px]' style={{borderBottom:'1px solid black'}} onClick={navigating('/resources')}>
                            <div className='text-[16px] mr-[24px]'>Explore our resources</div>
                            <RightArrowSvg/>
                        </div>
                    </div>


                    <div className='flex w-full justify-between mt-[48px] d-row-m-col'>
                        <div className='flex flex-col relative text-white bg-[#0050C8] d-w-30-m-100 mb-[20px]'>
                            <div className='relative h-[62px] flex items-center justify-center text-[24px] font-bold'>
                                <TopLeftTriangleSvg width={40} color='#2FC0E0' className='absolute top-0 left-0'/>
                                Teachers
                            </div>
                            <img className='w-full' src='/rr1.png' alt='resource'/>
                            <div className='text-[12px] px-[24px] pt-[48px] pb-[64px] text-center' style={{minHeight:250}}>
                                Access a wealth of previous materials and benefit from the exclusive programmes and educational workshops. 
                                <br/>Help us meet your needs to explore tailored educational activities designed to align with school curricula together.
                            </div>
                            <div className='w-[103%] absolute bottom-[-2px] left-[-.5px] flex justify-center items-center' onClick={navigating('/resources?ref=teachers')}>
                                <DetailsButtonSvg/>
                                <div className='absolute font-bold text-[18px] cursor-pointer hover:font-[900]'>{`Details >`}</div>
                            </div>
                        </div>

                        <div className='flex flex-col relative text-white bg-[#2FC0E0] d-w-30-m-100 mb-[20px]'>
                            <div className='relative h-[62px] flex items-center justify-center text-[24px] font-bold'>
                                <TopLeftTriangleSvg width={40} color='#0092C0' className='absolute top-0 left-0'/>
                                Artists
                            </div>
                            <img className='w-full' src='/rr2.png' alt='resource'/>
                            <div className='text-[12px] px-[24px] pt-[48px] pb-[64px] text-center' style={{minHeight:250}}>
                                Reach out to initiate projects and cross disciplinary collaborations with the Arts
                            </div>
                            <div className='w-[103%] absolute bottom-[-2px] left-[-.5px] flex justify-center items-center' onClick={navigating('/resources?ref=artists')}>
                                <DetailsButtonSvg color='#0092C0'/>
                                <div className='absolute font-bold text-[18px] cursor-pointer hover:font-[900]'>{`Details >`}</div>
                            </div>
                        </div>

                        <div className='flex flex-col relative text-white bg-[#00193F] d-w-30-m-100 mb-[20px]'>
                            <div className='relative h-[62px] flex items-center justify-center text-[24px] font-bold'>
                                <TopLeftTriangleSvg width={40} color='#0050C8' className='absolute top-0 left-0'/>
                                Patron
                            </div>
                            <img className='w-full' src='/rr3.png' alt='resource'/>
                            <div className='text-[12px] px-[24px] pt-[48px] pb-[64px] text-center' style={{minHeight:250}}>
                            Donate to our Digital Arts Education Scheme and help us to continue developing quality education materials as well as making art available to the community.
                            </div>
                            <div className='w-[103%] absolute bottom-[-2px] left-[-.5px] flex justify-center items-center' onClick={navigating('/resources?ref=patron')}>
                                <DetailsButtonSvg color='#0050C8'/>
                                <div className='absolute font-bold text-[18px] cursor-pointer hover:font-[900]'>{`Details >`}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-w-65px-m-16px h-full absolute left-0 top-0 bottom-0 bg-[#00193F]"/>
            </div>
        </div>
    )
}
