import '../../screens/register.css'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Card from './Card';
const info = {
    teachers:{
        type:'Teachers',
        text:['Access a wealth of previous materials and benefit from the exclusive programmes and educational workshops.', 'Help us meet your needs to explore tailored educational activities designed to align with school curricula together.']
    },
    artists:{
        type:'Artists',
        text:['Reach out to initiate projects and cross disciplinary collaborations with the Arts']
    },
    patron:{
        type:'Patron',
        text:['Donate to our Digital Arts Education Scheme and help us to continue developing quality education materials as well as making art available to the community.']
    },
}
const jsontemp = {
    userType: undefined,

}
function Screen({handleUpdateUserData ,userData, handleSetRegisterStep}) {
    function goNextStep(){
        if(userData.userType){
            handleUpdateUserData('userType', 'Teacher')
            handleSetRegisterStep(true)
        }
    }
    return (
        <div className='h-full w-full text-[black] h-full bg-[#fff] flex flex-col'>
            <div className='relative w-full min-h-[117px] bg-[#2FC0E0] flex flex-row flex-wrap items-center justify-between just-center px-[63px] gap-2'>
                <div className='flex flex-row items-center gap-4 min-w-[299px] three-card-hide'>
                    <img src={'/artfestLogo.png'} className='min-w-[156px] min-h-[39px] w-[156px] h-[39px]'/>
                    <img src={'/artedLogo.png'} className='min-w-[127px] min-h-[27px] w-[127px] h-[27px]'/>
                </div>
                <div className='flex flex-col items-center min-w-[250px] mb-[17px]'>
                    <div className='flex flex-row items-center gap-4 flex-1 min-w-[250px] mobile-show'>
                        <img src={'/artfestLogo.png'} className='min-w-[156px] min-h-[39px] w-[156px] h-[39px]'/>
                        <img src={'/artedLogo.png'} className='min-w-[127px] min-h-[27px] w-[127px] h-[27px]'/>
                    </div>
                    {/* <div className='text-white text-24 font-bold'>Thank you for applying YFS!</div> */}
                    <div className='text-white text-20 text-center'>Please follow the process to complete your registration</div>
                </div>
                <div className='max-w-[299px] flex-1 three-card-hide'></div>
            </div>
            <div className='flex flex-col items-center justify-center mb-[118px]'>
                <div className='font-bold text-[40px]'>You Are:</div>
                <div className='three-card-flex gap-4 items-center justify-center'>
                    <div onClick={() => {handleUpdateUserData('userType', 'Teacher')}}>
                        <Card userData={userData} content={info.teachers}/>
                    </div>
                    <div onClick={() => {handleUpdateUserData('userType', 'Artist')}}>
                        <Card userData={userData} content={info.artists}/>
                    </div>
                    <div onClick={() => {handleUpdateUserData('userType', 'Patron')}}>
                        <Card userData={userData} content={info.patron}/>
                    </div>
                </div>
            </div>
            <div className='flex flex-row items-center justify-center' onClick={() => {goNextStep()}}>
                <div className='bg-[#0050C8] max-w-[409px] flex-1 rounded-full text-center text-white py-[18px] font-bold'>Next</div>
            </div>
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
})(Screen)