export const RESOURCES= [
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/Videos/Student+Matinee+/2023.mp4',
        title:'Site-Specific Performance: Stages Are Everywhere',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/art00001_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/art000011.png',
        publish:'Published on : 10/04/2023',
        genere: 'Theatre',
        subject: 'Arts X Tech',
        format: 'Article',
        id: 'Site-Specific Performance: Stages Are Everywhere',
        type:'article',
        data:[
            {type:'text',data:"If you are invited to watch a play, would you immediately picture sitting in an enormous theatre with a proscenium arch? In fact, theatrical performance is evolving over time and unconventional forms are constantly appearing. Site-specific performance, for example, is gaining worldwide popularity these days. By utilising the characteristics peculiar to the performing space, the show is inextricably intertwined with the surroundings—a unique and rich experience for the audience. "},
            {type:'text',data:"The oldest theatre architecture can be found in ancient Greece in which a circular stage, where the performers stood and did simple acting, was surrounded by a fan-shaped auditorium. The completion of Teatro Farnese in Parma, Italy in 1618 signified the birth of proscenium theatre, which became the prototype of modern theatre. Proscenium stage performance has been in vogue over the centuries and is still predominating now. It is undeniably a timeless form of theatrical performance."},
            {type:'image',subtitle:'Teatro Farnese in Parma, Italy',url:"https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/art00001.png"},
            {type:'text',data:"Nevertheless, art is ever-changing. Contemporary artists free themselves from any systems, existing concepts and styles, aiming to pursue different forms and meanings in the process of artmaking. In 1966, American artist Robert Morris, generally known as a pioneering minimalist, wrote in Notes on Sculpture that “The better new work takes relationships out of the work and makes them a function of space, light, and the viewer’s field of vision”. His idea later stimulated the discussion on the principles of Site Specificity in artmaking—if a work is exhibited in a specific site, the inextricable connection of the work and the site would produce profound values one cannot find anywhere else. In other words, the site is a crucial component of the work. Its characteristics are fully utilised by the artist. Site-specific art is completely different from those exhibiting in neutral spaces such as galleries or museums. Artists need to cope with the specificities of alternative sites, including the unpredictable environmental changes in outdoor areas, specific functions of a place, or the collective memory and history involved. By responding and reacting to these natural or artificial spaces, the work would provide far more perspectives and interpretations than those seen in conventional exhibitions. Under the contemporary development of opposing the system and the art market, site-specific art is commonly created in the forms of Public Art, Installation Art and Land Art, in which the exhibition space is always the crucial contributor of these interactive works. "},
            {type:'text',data:"Theatre is highly influenced by the site-specific trend too. Besides traditional theatre, theatrical shows now take place in more alternative and casual venues—be it the tranquil rural area, or the bustling streets in cities, potential stages are everywhere. Theatrical performance is no longer a passive enjoyment to the audience like before. Instead of quietly sitting in the auditorium, they can participate or intervene in the show by following the cues of the artists—entering the stage, joining the performance and bonding with the space. The audience would build a reciprocal relationship with the show and go home with a rewarding theatrical experience. Their unduplicated improvisations will also enrich and transform the show."},
            {type:'image',subtitle:'© Yoan Boselli',url:"https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/art000011.png"},
            {type:'text',data:"The 51st Hong Kong Arts Festival proudly presents the immersive opera Vixen, a groundbreaking collaboration of Hong Kong opera talents and the UK-based Silent Opera. With the ambition of breaking the boundary and bringing the Czech folklore The Cunning Little Vixen to a wider audience, the team puts on the adaptation of the classic story in Tai Kwun, Hong Kong. In this innovative show, the audience follows the performers to roam around different parts of Tai Kwun. The team endeavours to create an authentic experience for the audience to enter the universe of the story in person, thus bridging the gap between the show and people. Not only do the participants can explore the rich heritage of Tai Kwun but can also reflect on the self-discovering journey of the story, immersing themselves in the special version of Vixen exclusively in Hong Kong."},,
            {type:'link',data:"Follow the Girl: The Making of Vixen",url:'https://digital.artsfestival.org/en/video/follow-girl-making-vixen'},
        ]
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2024/2024+Multimedia+Choral+Concert_+Garden+of+Repose+YFS+Appreciation+Guide.pdf',
        title:'Multimedia Choral Concert - Garden of Repose YFS Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00001_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00001.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'Multimedia-Choral-Concert-Garden-of-Repose-YFS-Appreciation-Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2024/2024+Chamber+Choir+Concert_+Van+Gogh+in+Me_YFS++Impressionism+Appreciation+Guide+.pdf',
        title:'Chamber Choir Concert - Van Gogh in Me - YFS  Impressionism Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00002_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00002.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'Chamber-Choir-Concert-Van-Gogh-in-Me-YFS-Impressionism-Appreciation-Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2024/2024+YFS+African+Music+Appreciation+Guide.pdf',
        title:'YFS African Music Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00003_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00003.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'YFS-African-Music-Appreciation-Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2024/2024+YFS+Chinese+Opera+Programmes+Appreciation+Guide.pdf',
        title:'YFS Chinese Opera Programmes Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00004_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00004.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'YFS-Chinese-Opera-Programmes-Appreciation-Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2024/2024+YFS+Dance+Special_+Flow+Appreciation+Guide+.pdf',
        title:'YFS Dance Special - Flow Appreciation Guide ',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00005_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00005.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'YFS-Dance-Special-Flow-Appreciation-Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2024/2024+YFS+Programmes+of+Shakespeare_s+Works+Appreciation+Guide.pdf',
        title:'YFS Programmes of Shakespeare’s Works Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00006_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00006.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'YFS Programmes of Shakespeare’s Works Appreciation Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2024/2024+YFS+Theatre+Special_+All+Things+At+Once+Appreciation+Guide.pdf',
        title:'YFS Theatre Special - All Things At Once Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00007_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a00007.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'YFS Theatre Special - All Things At Once Appreciation Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2023/2023+Dance+Opera_+Love+Stream+YFS+Appreciation+Guide_singlepage.pdf',
        title:'Dance Opera - Love Stream YFS Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202301_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202301.png',
        publish:'Published on : 2023',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'Dance Opera - Love Stream YFS Appreciation Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2023/2023+Music+Theatre_+Book+of+Water+YFS+Appreciation+Guide+.pdf',
        title:'Music Theatre - Book of Water YFS Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202302_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202302.png',
        publish:'Published on : 2023',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'Music Theatre - Book of Water YFS Appreciation Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2023/2023+YFS+Cantonese+Opera+Programme+Appreciation+Guide.pdf',
        title:'YFS Cantonese Opera Programme Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202303_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202303.png',
        publish:'Published on : 2023',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'YFS Cantonese Opera Programme Appreciation Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2023/2023+YFS+Music+Special_+Romantic+Cabaret+in+the+City+Appreciation+Guide.pdf',
        title:'YFS Music Special - Romantic Cabaret in the City Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202304_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202304.png',
        publish:'Published on : 2023',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'YFS Music Special - Romantic Cabaret in the City Appreciation Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/2023/2023+YFS+Music+Theatre+Special_+Radio+Wave+Hunter+Appreciation+Guide.pdf',
        title:'YFS Music Theatre Special - Radio Wave Hunter Appreciation Guide',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202305_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/a202305.png',
        publish:'Published on : 2023',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'YFS Music Theatre Special - Radio Wave Hunter Appreciation Guide',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/Student+Matinee/2019+Hamburg+Ballet.pdf',
        title:'2019 Hamburg Ballet',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/sm00001_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/sm00001.png',
        publish:'Published on : 2019',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: '2019 Hamburg Ballet',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/Student+Matinee/2023+Ballet+of+Slovene+National+Theatre+Maribor.pdf',
        title:'2023 Ballet of Slovene National Theatre Maribor',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/sm00002_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/sm00002.png',
        publish:'Published on : 2023',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: '2023 Ballet of Slovene National Theatre Maribor',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/Student+Matinee/2024+Hankyung+arte+Philharmonic.pdf',
        title:'2024 Hankyung arte Philharmonic',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/sm00003_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/sm00003.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: '2024 Hankyung arte Philharmonic',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/Young+Reporter/2024.pdf',
        title:'Young Reporter 2024',
        hero:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/yr00001_.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/yr00001.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'Young Reporter 2024',
        type:'pdf',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/Videos/Student+Matinee+/2024.mov',
        title:'Student Matinee 2024',
        hero:'/archives/video.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/2024video.png',
        publish:'Published on : 2024',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'Student Matinee 2024',
        type:'video',
    },
    {   
        url:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/Videos/Student+Matinee+/2023.mp4',
        title:'Student Matinee 2023',
        hero:'/archives/video.png',
        preview:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/2023video.png',
        publish:'Published on : 2023',
        genere: 'xxx',
        subject: 'xxx',
        format: 'xxx',
        id: 'Student Matinee 2023',
        type:'video',
    }
]