import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';


import Hero from '../components/about/Hero'
import Content1 from '../components/about/Content1'
import Banner1 from '../components/about/Banner1'
import Content2 from '../components/about/Content2'
import ParallaxBanner from '../components/ParallaxBanner'

function Screen(props) {


    return (
        <div className='flex flex-col w-full text-[black] h-full bg-[#fff]'>
            <Hero handleSetRightNav={props.handleSetRightNav}/>
            <Content1/>
            <Banner1/>
            <Content2/>
            <ParallaxBanner srcollPostion={props.srcollPostion}/>
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
  })(Screen)
  