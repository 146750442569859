import RightArrowSvg from '../../svgs/RightArrow';
import { useLocation, useNavigate } from 'react-router-dom';


export default function Component(props){
    const navigate = useNavigate();
    const location = useLocation()
    const navigating=(str)=>()=>{
      navigate(str)
    }
    return(
        <div className='relative w-full flex flex-col bg-[#EFF2F7]'>
            <div className="relative w-full flex flex-col items-center justify-end text-[white] d-px100-m-px24 text-black">
                <div className='max-w-[1200px] w-full flex flex-col py-[100px]'>
                    <div className='flex d-row-m-col w-full justify-between items-start'>
                        <div className='flex flex-row items-center flex-[2]'>
                            <div className='flex flex-col'>
                                <div className='text-[36px]'>Engage Art with Digital Platform</div>
                                <div className='text-[16px]'>Welcome to the Digital Arts Education Platform of the Hong Kong Arts Festival! We are delighted to share a broad spectrum of arts educational materials on this new platform. We hope that you will find the resources interesting, and enjoy exploring the infinite possibilities of the arts with us.</div>
                            </div>
                        </div>
                        <div className='flex-[1] flex flex-row justify-end items-start pt-[24px]'>
                            <div className='flex flex-row items-center pb-[8px] cursor-pointer hover:font-bold ' style={{borderBottom:'1px solid black'}} onClick={navigating('/about')}>
                                <div className='text-[16px] mr-[24px]'>More About DAEP</div>
                                <RightArrowSvg/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           <div className='w-full flex d-row-m-col text-black bg-[white]' style={{border:'2px solid #EFF2F7'}}>
                <div className='d-w-50-m-100 flex flex-col'>
                    <img src='/xx1.png' className='w-[100%]' alt='about'/>
                    <div className='w-[full] flex flex-col d-px100-m-px24 pt-[50px] pb-[100px]' style={{borderRight:'1px solid #EFF2F7'}}>
                        <div className='text-[30px] font-bold'>About the Hong Kong Arts Festival</div>
                        <div className='text-[16px] mt-[24px]'>Launched in 1973, the Hong Kong Arts Festival is a major international arts festival committed to enriching the cultural life of the city. In February and March every year, the Festival presents leading local and international artists in all genres of the performing arts, placing equal importance on great traditions and contemporary creations. The Festival also commissions and produces work...</div>
                        <div className='flex flex-row justify-start items-start pt-[36px]'>
                            <div className='flex flex-row items-center pb-[8px] cursor-pointer hover:font-bold ' style={{borderBottom:'1px solid black'}} onClick={navigating('/about')}>
                                <div className='text-[16px] mr-[24px]'>More Details</div>
                                <RightArrowSvg/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-w-50-m-100 flex flex-col'>
                    <img src='/xx2.png' className='w-[100%]' alt='about'/>
                    <div className='w-[100%] flex flex-col d-px100-m-px24 pt-[50px] pb-[100px]' style={{borderLeft:'1px solid #EFF2F7'}}>
                        <div className='text-[30px] font-bold'>About our art education</div>
                        <div className='text-[16px] mt-[24px]'>Driven by its educational principle, “LEARN, EXPLORE, CREATE”, the Hong Kong Arts Festival offers a broad spectrum of arts education activities throughout the year, examples of which include the Young Friends programme, the Jockey Club Local Creative Talents Series, the Hong Kong Jockey Club Student Matinee Programme, and more. Curated with the aim of developing students’ interest and appreciation in the performing arts,...</div>
                        <div className='flex flex-row justify-start items-start pt-[36px]'>
                            <div className='flex flex-row items-center pb-[8px] cursor-pointer hover:font-bold ' style={{borderBottom:'1px solid black'}} onClick={navigating('/about')}>
                                <div className='text-[16px] mr-[24px]'>More Details</div>
                                <RightArrowSvg/>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    )
}
