import './nav.css'
import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import {LANGUAGES} from '../dto/language'
import { Login} from '../actions/actions';
import { useCookies } from 'react-cookie';
import {COOKIESPREFIX} from '../config.js'

//svg
import MagGlassSvg from '../svgs/nav/MagGlassSvg.js'
import BurgerLineSvg from '../svgs/nav/BurgerLineSvg.js'
import ArrowLeftSvg from '../svgs/nav/ArrowLeftSvg.js'

function Component(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const [curScreen, setCurScreen] = useState('/')
  const [cookies, setCookie, removeCookie] = useCookies([`@${COOKIESPREFIX}_cred`,`@${COOKIESPREFIX}_token`]);
  useEffect(() => {
    const pathName = location.pathname.split('/')[1]
    setCurScreen(`/${pathName}`)
  },[location])
  //scroll appear nav
  function navigating(str){
    // setCurScreen(str)
    navigate(str)
  }
  function logOut(){
    removeCookie(`@${COOKIESPREFIX}_cred`,{path:'/'});
    removeCookie(`@${COOKIESPREFIX}_token`,{path:'/'});
    props.dispatch(Login(false))
    navigate('/')
  }
  
  const [isMenuExpanded,setisMenuExpanded]=useState(false)
  function toogleMenu(){setisMenuExpanded(!isMenuExpanded)}

  const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
    useEffect(()=>{
        function handleWindowResize() {setWindowSize(getWindowSize()); }
        window.addEventListener('resize', handleWindowResize);
        return () => {window.removeEventListener('resize', handleWindowResize);};
  },[])

  const nav = (<div className='w-full h-full flex flex-row items-center justify-between max-w-[1200px]'>
    <div className='flex flex-row gap-8 items-center h-full' style={{zIndex: 99}}>
      <div className='flex items-center gap-4 cursor-pointer' onClick={(() => navigating('/'))}>
        <img src={'/artfestLogo.png'} className='min-w-[156px] min-h-[39px] w-[156px] h-[39px]'/>
        <img src={'/artedLogo.png'} className='min-w-[127px] min-h-[27px] w-[127px] h-[27px]'/>
      </div>
        <div onClick={(() => navigating('/about'))} className='cursor-pointer w-1200-none'>About</div>
        {/* program */}
        <div className='cursor-pointer h-full w-1200-none'>
          <div className='group/program relative h-full'>
            <div onClick={(() => navigating('/programmes'))} className='flex items-center gap-1 h-full'>
              <div>Programmes</div>
              <div className='w-[4px] h-[4px] rounded-full bg-white relative'></div>
            </div>
            <div className={'flex flex-col w-[280px] absolute rounded-b-xl py-[23px] px-[17px] invisible group-hover/program:visible'} style={props.heroNav ? {backgroundColor: '#ffffff10', backdropFilter: 'blur(5px)'} : {backgroundColor: '#00193F'}}>
              <div onClick={(() => navigating('/programmes'))} className='pb-[17px] border-b border-[rgba(255,255,255,0.5)] flex items-center justify-between'>
                <div>Teacher</div>
                <ArrowLeftSvg />
              </div>
              <div onClick={(() => navigating('/programmes'))} className='py-[17px] border-b border-[rgba(255,255,255,0.5)] flex items-center justify-between'>
                <div>Artists</div>
                <ArrowLeftSvg />
              </div>
              <div onClick={(() => navigating('/programmes'))} className='pt-[17px] flex items-center justify-between'>
                <div>Funder & Corportate</div>
                <ArrowLeftSvg />
              </div>
            </div>
          </div>
        </div>
        <div onClick={(() => navigating('/resources'))} className='cursor-pointer w-1200-none'>Resources</div>
    </div>
    <div className='flex flex-row gap-8 items-center h-full' style={{zIndex: 99}}>
      <MagGlassSvg  className='cursor-pointer w-600-none'/>
      <div onClick={(() => navigating('/'))} className='cursor-pointer w-600-none'>Login</div>
      <div className='w-[47px] h-[47px] rounded-full bg-[#FFFFFF33] flex justify-center items-center cursor-pointer' onClick={() => props?.handleSetRightNav(true)}>
        <BurgerLineSvg />
      </div>
    </div>
  </div>)
  if(props.heroNav) return (
    <div className='w-full d-px100-m-px24 flex justify-center items-center'>
      {nav}
    </div>
  )
  return (
    <div className={'absolute w-[full] top-[0px] left-0 right-0 text-white flex flex-col items-center justify-center slide-in-top d-px100-m-px24 ' + (props.show ? 'slide-in-top' : 'slide-out-top')} style={{zIndex:99}}>
        <div className='max-w-[1200px] w-full h-[91px] mt-[20px] rounded-[16px] flex flex-row justify-between bg-[#00193F] px-[16px]'  style={{zIndex:99}}>
          {nav}
        </div>
    </div>
  );
}

export default connect((store)=>{
  return{
    token:store.tokenReducer,
    control:store.controlReducer,
  }
})(Component)
