import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      d="M6.397 17.997V9.801h2.765l.411-3.209H6.397V4.548c0-.926.258-1.56 1.587-1.56h1.684V.128C8.848.038 8.025-.004 7.201 0 4.757 0 3.079 1.492 3.079 4.231v2.355H.332v3.21h2.753v8.201h3.312Z"
    />
  </svg>
)
export default SvgComponent
