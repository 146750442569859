import DetailsButtonSvg2 from '../../svgs/DetailsButtonSvg2';
import { useRef, useState } from 'react';


export default function Component(props){

    return(
        <div className='relative w-full flex flex-col bg-[#00193F] text-white'>
            <div className="relative w-full flex flex-col items-center justify-center text-[white] d-px100-m-px24">
                <div className='max-w-[1200px] w-full flex flex-col py-[200px]'>
                    <div className='flex flex-row w-full justify-between items-start'>
                        <div className='flex flex-row items-center flex-[2]'>
                            <div className='flex flex-col w-[70%]'>
                                <div className='text-[36px]'>Contact Us</div>
                                <div className='text-[16px] mt-[32px]'>
                                    Welcome to the Digital Arts Education Platform of the Hong Kong Arts Festival! We are delighted to share a broad spectrum of arts educational materials on this new platform. We hope that you will find the resources interesting,
                                </div>
                            </div>
                        </div>
                        <div className='w-[7vw] hover:opacity-[70%] cursor-pointer'>
                            <DetailsButtonSvg2/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
