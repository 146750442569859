import './nav.css'
import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

import MagGlassSvg from '../svgs/nav/MagGlassSvg'
import CloseSvg from '../svgs/nav/CloseSvg'
import TopLeftColorSvg from '../svgs/TopLeftTriangle'
import ArrowLeftSvg from '../svgs/nav/ArrowLeftSvg'
import YoutubeSvg from '../svgs/socials/YoutubeSvg'
import InstagramSvg from '../svgs/socials/InstagramSvg'
import FacebookSvg from '../svgs/socials/FacebookSvg'
import ArtFestSvg from '../svgs/logo/ArtFestDarkSvg'
function Component({rightNav, handleSetRightNav}) {
  const [delayNav, setDelayNav] = useState(false)
  const navigate = useNavigate();
  const location = useLocation()
  const navRef = useRef()
    useEffect(() => {
        console.log(rightNav)
        let timer = undefined
        let timer2 = undefined
        if(!rightNav){
            timer = setTimeout(() => setDelayNav(rightNav), 500)
            timer2 = setTimeout(() => {
                if(navRef.current)navRef.current.style.display = 'none'
            },1000)
        }else{
            if(navRef.current)navRef.current.style.display = 'flex'
            setDelayNav(rightNav)
        }
        return ()=>{
            if(timer)clearTimeout(timer)
            if(timer2)clearTimeout(timer2)
        }
    },[rightNav])
  function navigating(str){
    // setCurScreen(str)
    handleSetRightNav(false)
    navigate(str)
  }


  if(!delayNav) return <></>
  return (
    <div ref={navRef} className={'fixed top-0 left-0 w-[100vw] h-[100vh] ' + (rightNav ? 'fade-in-me' : 'fade-out-me')} style={{zIndex:100}}>
        <div className='absolute inset-0' onClick={() => handleSetRightNav(false)} style={{zIndex:100}} />
        <div>
            <div className={'absolute right-0 flex flex-col w-[30vw] h-[100vh] bg-white px-[50px] py-[44px] min-w-[404px] overflow-auto ' + (rightNav ? 'slide-in-right' : 'slide-out-right')} style={{zIndex:100}}>
                <div className='flex items-center justify-between'>
                    <div className='flex flex-wrap items-center gap-4 cursor-pointer' onClick={() => navigating('/')}>
                        {/* <img src={'/artfestDark.png'} /> */}
                        <ArtFestSvg />
                        <img src={'/artedDark.png'} className='w-[105px] h-[22px]'/>
                    </div>
                    <div className='flex items-center gap-12'>
                        <div className='cursor-pointer'>
                            <MagGlassSvg color={'#000000'}/>
                        </div>
                        <div className='cursor-pointer' onClick={() => handleSetRightNav(false)}>
                            <CloseSvg />
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-2 mt-[43px]'>
                    <div onClick={() => navigating('/login')} className='flex items-center justify-center relative flex-1 border cursor-pointer'>
                        <div className='absolute top-1 left-1'><TopLeftColorSvg color={'#2FC0E0'} /></div>
                        <div className='py-[12px] font-bold'>Login</div>
                    </div>
                    <div onClick={() => navigating('/register')} className='flex items-center justify-center relative flex-1 border cursor-pointer'>
                        <div className='absolute top-1 left-1'><TopLeftColorSvg /></div>
                        <div className='py-[12px] font-bold'>Register</div>
                    </div>
                </div>
                <div className='mt-[22px]'>
                    {['About Us', 'Resources', 'Programmes'].map((name, i) => (
                        <div onClick={() => {navigating(`/${name.toLocaleLowerCase().split(' ')[0]}`)}} key={`Nav${name}`} className={'cursor-pointer py-[24px] border-b border-[#00000033]'}>
                            <div className='flex items-center justify-between'>
                                <div className='flex gap-8'>
                                    <div className='text-[16px] text-[#6A6A6A] pt-[4px]'>0{i+1}</div>
                                    <div className='text-[28px]'>{name}</div>
                                </div>
                                <div className='w-[50px] h-[50px] rounded-full flex items-center justify-center border border-[#00000080]'>
                                    <ArrowLeftSvg color={'#000000'}/>
                                </div>
                            </div>
                            {name === 'Programmes' &&                                    
                                <ul className='ml-[74px]' style={{ listStyleType: 'disc' }}>
                                    <li className='text-[20px] py-[17px] cursor-pointer'>For Teachers</li>
                                    <li className='text-[20px] py-[17px] cursor-pointer'>For Artists</li>
                                    <li className='text-[20px] py-[17px] cursor-pointer'>For Funder & Corporate</li>
                                </ul>
                            }
                        </div>
                    ))}
                </div>
                <div className='flex flex-1 w-full' style={{}}>
                    <div className='flex flex-row-reverse w-full mt-auto gap-2 w-full'>
                        {[<FacebookSvg />, <InstagramSvg />, <YoutubeSvg />].map((svg, i) => (<div  key={`svg${i}`}className='w-[50px] h-[50px] flex items-center justify-center rounded-full border border-[#00000050] cursor-pointer'>
                            {svg}
                        </div>))}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default connect((store)=>{
  return{
    token:store.tokenReducer,
    control:store.controlReducer,
  }
})(Component)