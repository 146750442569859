import '../../screens/register.css'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

function Screen({handleSetRegisterStep}) {
    return (
        <div className='h-full w-full text-[black] h-full bg-[#fff]'>
        <div className='relative w-full min-h-[117px] bg-[#2FC0E0] flex flex-row flex-wrap items-center justify-between just-center px-[63px] gap-2'>
            <div className='flex flex-row items-center gap-4 min-w-[299px] three-card-hide'>
                <img src={'/artfestLogo.png'} className='min-w-[156px] min-h-[39px] w-[156px] h-[39px]'/>
                <img src={'/artedLogo.png'} className='min-w-[127px] min-h-[27px] w-[127px] h-[27px]'/>
            </div>
            <div className='flex flex-col items-center min-w-[250px] mb-[17px]'>
                <div className='flex flex-row items-center gap-4 flex-1 min-w-[250px] mobile-show'>
                    <img src={'/artfestLogo.png'} className='min-w-[156px] min-h-[39px] w-[156px] h-[39px]'/>
                    <img src={'/artedLogo.png'} className='min-w-[127px] min-h-[27px] w-[127px] h-[27px]'/>
                </div>
                <div className='text-white text-24 font-bold'>Instruction</div>
            </div>
            <div className='max-w-[299px] flex-1 three-card-hide'></div>
        </div>
        <div className='relative flex flex-col items-center w-full mt-[50px]'>
            <div className='absolute bg-[#0050C8] w-full max-w-[409px] flex-1 rounded-full text-center text-white py-[18px] font-bold three-card-hide'>Teacher</div>
            <img src={'/teacherInstruction.png'} className='max-w-[1151px] min-w-[1000px] pt-[24px] three-card-hide' style={{width:'40%', height:'auto'}}></img>
            <img src={'/teachIntructionPhone.png'} className='w-[390px] pt-[24px] mobile-show  mb-[24px]'></img>
            <div onClick={() => handleSetRegisterStep(true)} className='absolute bottom-0 bg-[#0050C8] w-full max-w-[409px] flex-1 rounded-full text-center text-white py-[18px] font-bold'>Next</div>
        </div>
    </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
})(Screen)
  