import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom'
import { connect } from 'react-redux';
import {useRef, useEffect, useState } from 'react';
import { Login } from './actions/actions'
import Footer from './components/Footer';
import NavBar from './components/Navbar';
import RightNavBar from './components/RightNavBar';

//pages
import PageNotFound from './screens/PageNotFound'
import Home from './screens/Home';
import About from './screens/About';
import Archives from './screens/Archives';
import Programs from './screens/Programs';
import Register from './screens/Register';
import ArchiveDetails from './screens/ArchiveDetails';

function App() {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [rightNav, setRightNav] = useState(false)
  const [srcollPostion,setsrcollPostion]= useState(0)
  const scrollRef = useRef()
  //
  const handleSetRightNav = (bol) => setRightNav(bol)
  const controlNavbar = () => {
    setShow(scrollRef.current.scrollTop > 970)
    setsrcollPostion(scrollRef.current.scrollTop/(scrollRef.current.scrollHeight))
  };
  useEffect(() => {
    scrollRef.current.scrollTop = 0
    console.log(location.pathname)
  },[location.pathname])
  useEffect(() => {
    scrollRef.current.addEventListener('scroll', controlNavbar);
    return () => {
      scrollRef.current.removeEventListener('scroll', controlNavbar);
    };
  },[]);
  return (
    <div ref={scrollRef} className="flex h-[100vh] h-min-[100vh] w-[100vw] w-min-[100vw] flex flex-col App" style={{letterSpacing:1.05,overflowX:"hidden",}}>
      {!['/register'].includes(location.pathname) && <NavBar show={show} handleSetRightNav={handleSetRightNav}/>}
      <RightNavBar rightNav={rightNav} handleSetRightNav={handleSetRightNav}/>
      <div className='flex flex-1 w-full'>
        <Routes >
          <Route path="/" element={<Home srcollPostion={srcollPostion} handleSetRightNav={handleSetRightNav}/>} />
          <Route path="/about" element={<About srcollPostion={srcollPostion} handleSetRightNav={handleSetRightNav}/>} />
          <Route path="/resources" element={<Archives srcollPostion={srcollPostion} handleSetRightNav={handleSetRightNav}/>} />
          <Route path="/register"  element={<Register/>} />
          <Route path="/programmes" element={<Programs  srcollPostion={srcollPostion} handleSetRightNav={handleSetRightNav}/>} />
          <Route path='/resources/:id' element={<ArchiveDetails srcollPostion={srcollPostion} handleSetRightNav={handleSetRightNav}/>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      {!['/register'].includes(location.pathname) && <Footer/>}
    </div>
  );
}

export default App;
