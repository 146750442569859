import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

import YoutubeSvg from '../svgs/socials/YoutubeSvg'
import InstagramSvg from '../svgs/socials/InstagramSvg'
import FacebookSvg from '../svgs/socials/FacebookSvg'
function Component(props) {
  const navigate = useNavigate();
  const location = useLocation()


  function navigating(str){
    // setCurScreen(str)
    navigate(str)
  }



  return (
    <div className='relative text-white bg-black flex items-center justify-center w-full py-[44px]' style={{}}>
      <div className='flex flex-col items-center max-w-[1200px] w-full'>
        <div className='flex gap-8 cursor-pointer' onClick={(() => navigating('/'))}>
          <img src={'/artfestLogo.png'} className='min-w-[195px] min-h-[44px] w-[195px] h-[44px]'/>
          <img src={'/artedLogo.png'} className='min-w-[163px] min-h-[34px] w-[163px] h-[34px]'/>
        </div>
        <div className='text-[16px] py-[40px]'>Founding Sponsors</div>
        <div className='flex gap-8 cursor-pointer  mb-[67px]' onClick={(() => navigating('/'))}>
          <img src={'/chaLogo.png'} className='min-w-[168px] min-h-[33px] w-[168px] h-[33px]'/>
          <img src={'/hkrLogo.png'} className='min-w-[195px] min-h-[28px] w-[195px] h-[28px]'/>
        </div>
        <div className='flex gap-2 w-full d-px100-m-px24'>
          {[<YoutubeSvg color={'#FFFFFF'}/>, <InstagramSvg color={'#FFFFFF'}/>, <FacebookSvg color={'#FFFFFF'}/>].map((svg, i) => (<div  key={`svg${i}`}className='w-[50px] h-[50px] flex items-center justify-center rounded-full border border-[#FFFFFF80] cursor-pointer'>
              {svg}
          </div>))}
        </div>
        <div className='flex flex-row gap-4 w-full py-[34px] border-b border-[#FFFFFF80] cursor-pointer d-px100-m-px24'>
          {['About', 'Programmes', 'Resources'].map((name) => (<div key={name + 'footer'} onClick={() => {navigating(`/${name.toLowerCase()}`)}}>{name}</div>))}
        </div>
      </div>
    </div>
  );
}

export default connect((store)=>{
  return{
    token:store.tokenReducer,
    control:store.controlReducer,
  }
})(Component)
