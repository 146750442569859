import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import Hero from '../components/programs/Hero'
import Content from '../components/programs/Content'
import ParallaxBanner from '../components/ParallaxBanner'


function Screen(props) {
    return (
        <div className='flex flex-col w-full text-[black] h-full bg-[#fff]'>
            <Hero handleSetRightNav={props.handleSetRightNav}/>
            <Content/>
            <ParallaxBanner srcollPostion={props.srcollPostion}/>
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
  })(Screen)
  