
import * as React from "react"
import Navbar from "../Navbar"

const IMAGES=[
  {imageUrl:'/hero.jpg'},
  {imageUrl:'https://hkartsfestival.s3.ap-southeast-1.amazonaws.com/images/220916B147.jpg'},
]
export default function Component(props){

    const [sliderIndex,setSliderIndex]=React.useState(0)
    React.useEffect(()=>{
      let sliderInterval = setInterval(()=>{setSliderIndex(s=>(s+1)%IMAGES.length)},5000)
      return ()=>{clearInterval(sliderInterval)}
    },[])

    return(
        <div className="relative w-full">
            <div className='absolute w-full top-[0px] left-[0px] right-[0px] navbar-h text-white flex flex-row items-center justify-center' style={{zIndex:97,}}>
                <div className='absolute w-full top-[0px] left-[0px] right-[0px] navbar-h text-white bg-[#ffffff10] flex flex-row items-center justify-between' style={{backdropFilter: 'blur(5px)'}} />
                <Navbar heroNav={true} handleSetRightNav={props.handleSetRightNav}/>
            </div>



            {/*slider*/}
            <div className="relative w-full" style={{overflow:'hidden',height:'100vh',minHeight:'700px'}}>
              <div className='flex flex-row justify-start items-start absolute' style={{width:`${IMAGES.length*100}%`,transition:'.5s all ease',top:0,left:`${-sliderIndex*100}%`}}>
                {IMAGES?.map((item,index)=>
                  <div key={index} className="relative w-[100vw]">
                    <img className='w-full' style={{height:'100vh',minHeight:'700px',objectFit:'cover'}} src={item.imageUrl} alt='hero'/>
                  </div>
                )}
              </div>
              <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-end text-[white] d-px100-m-px24">
                  <div className='max-w-[1200px] w-full flex flex-col'>
                      <div className="text-[24px] font-[700]">Engage Art with Digital Platform</div>
                      <div className="w-full flex flex-row my-[16px]">
                        <div className="flex-1 text-[36px]">
                          Digital Arts Education Platform
                        </div>
                        <div className="flex-1 text-[12px] text-">
                          Welcome to the Digital Arts Education Platform by the Hong Kong Arts Festival, where a rich tapestry of arts educational resources awaits your discovery.
                        </div>
                      </div>
                      <div className="relative w-full h-[1px] bg-[#ffffffA0]">
                        <div className="w-[33%] h-[3px] bg-[white] absolute" style={{width:`${100/IMAGES.length}%`,left:`${100/IMAGES.length*sliderIndex}%`,transition:'.5s all ease',top:-1.5}}/>
                      </div>
                      <div className="text-[16px] my-[24px]">Slide Image / Video 01</div>
                  </div>
              </div>
            </div>
            {/*slider*/}


            {/*Decorations*/}
            <div className="d-w-65px-m-16px h-full absolute left-0 top-0 bottom-0 bg-[#00193F]" style={{zIndex:98}}/>
            <div className='d-flex-m-hidden'><LeftDecoration/></div>
            <div className='d-hidden-m-flex'><LeftDecoration mobile/></div>
            <RightDecoration />
            {/*End of Decorations*/}

        </div>
    )
}



const LeftDecoration = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.mobile?110/2:110}
    height={props.mobile?139/2:139}
    viewBox="0 0 110 139"
    fill="none"
    className="absolute left-0 top-0"
    style={{zIndex:98}}
  >
    <path fill="#2FC0E0" d="M107.5 0H-2v118l34-84 75.5-34Z" />
    <path
      fill="#fff"
      d="M-2 112.023C-2 50.167 48.144 0 110 0c0 0-36.013 11.387-45.63 62.522C63.896 65.043-2 78.207-2 129.771v-17.748Z"
    />
  </svg>
)

const RightDecoration = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    viewBox="0 0 60 60"
    fill="none"
    className="absolute right-0 top-0"
    style={{zIndex:98}}
  >
    <path fill="#fff" d="M60 60V0H0l60 60Z" />
  </svg>
)
