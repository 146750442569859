import TopLeftTriangleSvg from '../../svgs/TopLeftTriangle';
import RightArrowSvg from '../../svgs/RightArrow';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RESOURCES } from '../../config/archives.config';

export default function Component(props){
    const navigate = useNavigate();
    const location = useLocation()
    const navigating=(str)=>()=>{
      navigate(str)
    }


    const scrollRef= useRef(null)
    let [scrollInterval,setscrollInterval]=useState(null)
    const scrollRight=()=>{
        if(scrollInterval)clearInterval(scrollInterval)
        let newInterval =setInterval(()=>{
            scrollRef.current.scrollLeft += 5;
        },10)
        setscrollInterval(newInterval)
    }
    const scrollLeft=()=>{
        if(scrollInterval)clearInterval(scrollInterval)
        let newInterval =setInterval(()=>{
            scrollRef.current.scrollLeft -= 5;
        },10)
        setscrollInterval(newInterval)
    }
    const clear=()=>{
        clearInterval(scrollInterval)
    }
    return(
        <div className='relative w-full flex flex-col'>
            <div className="relative w-full flex flex-col items-center justify-end text-[white] d-px100-m-px24 text-black">
                <div className='max-w-[1200px] w-full flex flex-col mt-[200px]'>
                    <div className='flex d-row-m-col items-start w-full justify-between'>
                        <div className='flex flex-row items-start'>
                            <TopLeftTriangleSvg className='mt-[13px]' color='#2FC0E0'/>
                            <div className='flex flex-col ml-[8px]'>
                                <div className='text-[36px]'>Resources</div>
                                <div className='text-[12px]'>Explore all the resources on our platform at your leisure.</div>
                            </div>
                        </div>
                        <div className='flex flex-row items-center pb-[8px] cursor-pointer hover:font-bold mt-[24px]' style={{borderBottom:'1px solid black'}} onClick={navigating('/resources')}>
                            <div className='text-[16px] mr-[24px]'>View all resources</div>
                            <RightArrowSvg/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative w-full flex flex-col items-end justify-end text-[white] text-black mt-[50px] mb-[200px] resources-scroll">
                <div ref={scrollRef} className='relative w-full flex flex-row justify-start items-start gap-[50px] hidescrollbar' style={{maxWidth:'calc(50vw + 600px)' ,overflowX:'auto'}}>
                    {RESOURCES.slice(0, 10)?.map((item,index)=>
                        <div key={index} className='flex flex-col cursor-pointer' style={{width:"530px"}} onClick={navigating(`/resources/${item?.id}`)}>
                            <img src={item?.preview} style={{width:530,minWidth:"530px",objectFit:'cover',aspectRatio:1151/648,height:undefined}} alt='archive'/>
                            <div className='text-[#8C8C8C] text-[20px] w-full'>{item?.title}</div>
                        </div>
                    )}
                   
                </div>
                <div className='h-full bg-[#ff000000] w-[100px] absolute right-0 d-flex-m-hidden' onMouseEnter={scrollRight} onMouseLeave={clear}></div>
                <div className='h-full bg-[#ff000000] w-[100px] absolute d-flex-m-hidden' style={{right:'min( calc(100% - 200px), calc(50vw + 500px))'}} onMouseEnter={scrollLeft} onMouseLeave={clear}></div>
            </div>

        </div>
    )
}
