export default function Component(props) {
    return (
      <div className='relative w-full flex flex-col text-black'>
        <div className="relative w-full flex flex-col items-center justify-center d-px100-m-px24">
            <div className='max-w-[1200px] w-full flex flex-col py-[200px]'>
                <div className="text-[36px] w-full">About our art education</div>
                <div className="text-[16px] w-full mt-[24px]">Driven by its educational principle, “LEARN, EXPLORE, CREATE”, the Hong Kong Arts Festival offers a broad spectrum of arts education activities throughout the year, examples of which include the Young Friends programme, the Jockey Club Local Creative Talents Series, the Hong Kong Jockey Club Student Matinee Programme, and more. Curated with the aim of developing students’ interest and appreciation in the performing arts, these arts educations activities provide students with range of ancillary information before, during and after the performances.</div>
                <div className="text-[16px] w-full mt-[24px]">As technology continues to advance in our society, HKAF periodically makes adjustments to adapt to prevailing conditions. Our arts education activities are now offered in person and via online platforms, allowing our productions to develop in various forms to meet the diverse needs of schools and students. With a focus in harnessing Arts Tech, we are also exploring the emerging field of interdisciplinary arts development to push students’ understanding of the arts and their imaginations to the next level.</div>
            </div>
        </div>
      </div>
    );
  }
  
  