import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Hero from '../components/home/Hero'
import Resources from '../components/home/Resources'
import Archives from '../components/home/Archives'
import About from '../components/home/About'
import ContactUs from '../components/home/ContactUs'
import News from '../components/home/News'
import Donate from '../components/home/Donate'
import ParallaxBanner from '../components/ParallaxBanner'

function Screen(props) {

    const [mapType,setmapType]=useState('hybrid')
    return (
        <div className='flex flex-col w-full text-[black] h-full bg-[#fff]'>
            <Hero handleSetRightNav={props.handleSetRightNav}/>
            <Resources />
            <Donate/>
            <ParallaxBanner offset={0} srcollPostion={props.srcollPostion}/>
            <About />
            <News/>
            <ContactUs/>
            <Archives />
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
  })(Screen)
  