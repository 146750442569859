import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import YouAre from '../components/register/YouAre';
import Teacher2 from '../components/register/Teacher2';
import Tacher3 from '../components/register/Teacher3'

import Complete from '../components/register/Complete';
function Screen(props) {
    const [userData, setUserData] = useState({userType:"Teacher"})
    const [registerStep, setRegisterStep] = useState(1)
    function handleUpdateUserData(key, val){
        setUserData(prev => {return{...prev, [key]: val}})
    }
    function handleSetRegisterStep(bol){
        if(bol){
            setRegisterStep(prev => prev+1)
        }else{
            setRegisterStep(prev => prev-1)
        }
    }
    return (
        <div className='w-full h-full text-[black] bg-[#fff]'>
            {registerStep === 1 && <YouAre handleSetRegisterStep={handleSetRegisterStep} userData={userData} handleUpdateUserData={handleUpdateUserData}/>}
            {(registerStep === 2 && userData.userType === 'Teacher') && <Teacher2 handleSetRegisterStep={handleSetRegisterStep}/>}
            {(registerStep === 3 && userData.userType === 'Teacher') && <Tacher3 handleSetRegisterStep={handleSetRegisterStep} userData={userData} handleUpdateUserData={handleUpdateUserData}/>}
            {(registerStep === 4 && userData.userType === 'Teacher') && <Complete />}
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
})(Screen)
  