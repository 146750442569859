import RightArrowSvg from '../../svgs/RightArrow';
import { useLocation, useNavigate } from 'react-router-dom';


export default function Component(props){
    const navigate = useNavigate();
    const location = useLocation()
    const navigating=(str)=>()=>{
      navigate(str)
    }
    return(
        <div className='relative w-full flex flex-col bg-[#00193F] text-[white]'>
            <div className="relative w-full flex flex-col items-center justify-end d-px100-m-px24">
                <div className='max-w-[1200px] w-full flex flex-col py-[50px]'>
                    <div className='flex d-row-m-col w-full justify-between items-start'>
                        <div className='flex flex-row items-center flex-[2]'>
                            <div className='flex flex-col'>
                                <div className='text-[36px]'>Support Arts Education with HKAF</div>
                            </div>
                        </div>
                        <div className='flex-[1] flex flex-row justify-end items-start pt-[24px]'>
                            <div className='flex flex-row items-center pb-[8px] cursor-pointer hover:font-bold ' style={{borderBottom:'1px solid white'}}>
                                <div className='text-[16px] mr-[24px]'>Donate</div>
                                <RightArrowSvg color='white'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           <div className='w-full flex d-row-m-col text-black bg-[white]' style={{}}>
                <div className='d-w-33-m-100 flex flex-col'>
                    <img src='/aa1.png' className='w-[100%]' alt='about'/>
                    <div className='w-[full] flex flex-col d-px50-m-px24 pt-[50px] pb-[100px]' style={{borderRight:'1px solid #EFF2F7'}}>
                        <div className='text-[24px] text-[#0050C8]'>Rich Global Connections</div>
                        <div className='text-[16px] mt-[24px]'>HKAF is a top-tier platform frequently partners with renowned international artists and institutions to produce exceptional works.</div>
                        
                    </div>
                </div>
                <div className='d-w-33-m-100 flex flex-col'>
                    <img src='/aa2.png' className='w-[100%]' alt='about'/>
                    <div className='w-[100%] flex flex-col d-px50-m-px24 pt-[50px] pb-[100px]' style={{borderLeft:'1px solid #EFF2F7'}}>
                        <div className='text-[24px] text-[#0050C8]'>Embrace Artistic Diversity</div>
                        <div className='text-[16px] mt-[24px]'>HKAF has presented top international artists and ensembles across multiple genres, such as western opera, chinese opera, classical music, jazz and world music, dance, theatre, large-scale special events etc.</div>

                    </div>
                </div>
                <div className='d-w-33-m-100 flex flex-col'>
                    <img src='/aa3.png' className='w-[100%]' alt='about'/>
                    <div className='w-[100%] flex flex-col d-px50-m-px24 pt-[50px] pb-[100px]' style={{borderLeft:'1px solid #EFF2F7'}}>
                        <div className='text-[24px] text-[#0050C8]'>Time-Honored Credibility</div>
                        <div className='text-[12px] mt-[24px]'>Launched in 1973 and with over fifty years of expertise, HKAF stands as a prominent international arts festival dedicated to enhancing the city's cultural landscape.</div>

                    </div>
                </div>
           </div>
        </div>
    )
}
