import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="m9.954 16-1.312-1.296 5.403-5.403H.051V7.426h13.994L8.642 2.04 9.954.727l7.637 7.637L9.954 16Z"
    />
  </svg>
)
export default SvgComponent
