import '../../screens/register.css'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import { PostSurvey } from '../../services/survey.server';
function Screen({handleUpdateUserData ,userData, handleSetRegisterStep}) {
    const [isFirst, setIsFirst] = useState(true)
    async function handleSubmitInfo(){
        setIsFirst(false)
        const keys=['name','title','org','teaching','help','email','phone']
        for(const key of keys){
            if(!userData[key]) return;
        }
        const res = await PostSurvey(userData)
        if(res.errno)return;
        handleSetRegisterStep(true)
    }
    return (
    <div className='h-full w-full text-[black] h-full bg-[#fff]'>
        <div className='relative w-full min-h-[117px] bg-[#2FC0E0] flex flex-row flex-wrap items-center justify-between just-center px-[63px] gap-2'>
            <div className='flex flex-row items-center gap-4 min-w-[299px] three-card-hide'>
                <img src={'/artfestLogo.png'} className='min-w-[156px] min-h-[39px] w-[156px] h-[39px]'/>
                <img src={'/artedLogo.png'} className='min-w-[127px] min-h-[27px] w-[127px] h-[27px]'/>
            </div>
            <div className='flex flex-col items-center min-w-[250px] mb-[17px]'>
                <div className='flex flex-row items-center gap-4 flex-1 min-w-[250px] mobile-show'>
                    <img src={'/artfestLogo.png'} className='min-w-[156px] min-h-[39px] w-[156px] h-[39px]'/>
                    <img src={'/artedLogo.png'} className='min-w-[127px] min-h-[27px] w-[127px] h-[27px]'/>
                </div>
                <div className='text-white text-24 font-bold'>Please fill the following questions</div>
            </div>
            <div className='max-w-[299px] flex-1 three-card-hide'></div>
        </div>
        <div className='flex flex-col items-center justify-center w-full mt-[36px] d-px100-m-px24'>
            <div className='max-w-[1133px] w-full'>
                <div className='font-24-16 font-medium	text-[#0050C8]'>Your Name :</div>
                <input onChange={(e) => handleUpdateUserData('name', e.target.value)} placeholder='please fill your name' className='w-full outline-none border-b' style={{borderColor: ((userData.name?.length > 0 || isFirst) ? '#0050C8' : 'red')}}></input>
            </div>
            <div className='max-w-[1133px] w-full mt-[32px]'>
                <div className='font-24-16 font-medium	text-[#0050C8]'>Title :</div>
                <input onChange={(e) => handleUpdateUserData('title', e.target.value)} placeholder='please fill your title' className='w-full outline-none border-b' style={{borderColor: ((userData.title?.length > 0 || isFirst) ? '#0050C8' : 'red')}}></input>
            </div>
            <div className='max-w-[1133px] w-full mt-[32px]'>
                <div className='font-24-16 font-medium	text-[#0050C8]'>School/ Organisation : </div>
                <input onChange={(e) => handleUpdateUserData('org', e.target.value)} placeholder='Please fill your school or organisation :' className='w-full outline-none border-b' style={{borderColor: ((userData.org?.length > 0 || isFirst) ? '#0050C8' : 'red')}}></input>
            </div>
            <div className='max-w-[1133px] w-full mt-[32px]'>
                <div className='font-24-16 font-medium	text-[#0050C8]'>Education/ Research Interests :  </div>
                <input onChange={(e) => handleUpdateUserData('teaching', e.target.value)} placeholder='Please fill your interests' className='w-full outline-none border-b' style={{borderColor: ((userData.teaching?.length > 0 || isFirst) ? '#0050C8' : 'red')}}></input>
            </div>
            <div className='max-w-[1133px] w-full mt-[32px]'>
                <div className='font-24-16 font-medium	text-[#0050C8]'>Help us meet your needs :   </div>
                <textarea onChange={(e) => handleUpdateUserData('help', e.target.value)} placeholder='e.g. In-school workshops, field trips, interdisciplinary learning, education kit in classroom, artists in residency programme etc.' className='w-full outline-none border' style={{borderColor: ((userData.help?.length > 0 || isFirst) ? '#0050C8' : 'red')}}></textarea>
            </div>
            <div className='max-w-[1133px] w-full flex flex-row flex-wrap gap-4 mt-[32px]'>
                <div className='flex-1 min-w-[400px]'>
                    <div className='font-24-16 font-medium	text-[#0050C8]'>Email Address :   </div>
                    <input onChange={(e) => handleUpdateUserData('email', e.target.value)} placeholder='Please fill your email address' className='w-full outline-none border-b' style={{borderColor: ((userData.email?.length > 0 || isFirst) ? '#0050C8' : 'red')}}></input>
                </div>
                <div className='flex-1 min-w-[400px]'>
                    <div className='font-24-16 font-medium	text-[#0050C8]'>Contact Number :  </div>
                    <input onChange={(e) => handleUpdateUserData('phone', e.target.value)} placeholder='Please fill your phone number' className='w-full outline-none border-b' style={{borderColor: ((userData.phone?.length > 0 || isFirst) ? '#0050C8' : 'red')}}></input>
                </div>
            </div>
        </div>
        <div className='flex flex-col items-center w-full mt-[50px] d-px100-m-px24'>
            <div onClick={() => handleSubmitInfo()} className='bg-[#0050C8] w-full max-w-[409px] flex-1 rounded-full text-center text-white py-[18px] font-bold cursor-pointer'>Next</div>
        </div>
    </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
})(Screen)
  