import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||20}
    height={props.width||20}
    viewBox="0 0 50 50"
    fill="none"
    {...props}
  >
    <path fill={props.color||"#0050C8"} d="M50 0H0v50L50 0Z" />
  </svg>
)
export default SvgComponent
