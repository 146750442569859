import '../../screens/register.css'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function Screen({handleSetRegisterStep}) {
    const navigate = useNavigate()
    return (
        <div className='relative h-full w-full text-[black] h-full bg-[#fff]'>
        <div className='absolute w-full min-h-[117px] bg-[#2FC0E0] flex flex-row flex-wrap items-center justify-center px-[63px] gap-2'>
            <div className='flex flex-row items-center gap-4 flex-1 min-w-[250px]'>
                <img src={'/artfestLogo.png'} className='min-w-[156px] min-h-[39px] w-[156px] h-[39px]'/>
                <img src={'/artedLogo.png'} className='min-w-[127px] min-h-[27px] w-[127px] h-[27px]'/>
            </div>
            <div className='flex flex-col items-center flex-1 min-w-[250px]'>
            </div>
            <div className='flex-1 min-w-[250px]'></div>
        </div>
        <div className='flex flex-col flex-1 items-center justify-center w-full h-full'>
            <div className='font-48-24 font-bold text-center'>Your registration is completed</div>
            <div className='mt-[77px] text-center'>Your registration is completed! We will contact you soon about the updates. Please stay tuned!
            <br/>Thank you.</div>
            <div className='flex flex-col items-center w-full mt-[50px]'>
                <div onClick={() => navigate('/')} className='bg-[#0050C8] w-full max-w-[409px] flex-1 rounded-full text-center text-white py-[18px] font-bold cursor-pointer'>Leave</div>
            </div>
        </div>
    </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
})(Screen)
  