import TopLeftTriangleSvg from '../../svgs/TopLeftTriangle'
export default function Component(props) {
    return (
        <div className='relative w-full flex justify-center items-center'>
            <img src='/banner2.png' className='w-full' style={{minHeight:300,objectFit:'cover'}} alt='banner'/>
            
            <div className='absolute text-[12px] max-w-[1400px] w-full flex absolute bg-[#00000030] flex items-center justify-center text-white py-[80px] d-px100-m-px24' style={{backdropFilter:'blur(10px)'}}>
                HKAF is a non-profit organisation. Its principal income sources are: recurrent funding from the government, box office revenues, and sponsorship and donations made by corporations, individuals and charitable foundations which form a significant portion of total income and also enable HKAF to receive support from the Government’s matching scheme that matches income generated through private sector sponsorship and donations.
                <TopLeftTriangleSvg width={40} color='#ffffff' className='absolute top-[15px] left-[15px]'/>
            </div>
        </div>
    );
  }
  
  