import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    fill="none"
    {...props}
  >
    <path
      fill="#D6DBE5"
      d="M0 0h100v100s-1.37-43.644-31.05-71.186C39.27 1.27 0 0 0 0Z"
    />
  </svg>
)
export default SvgComponent
