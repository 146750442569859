import { server_ip } from "../config";

async function PostSurvey(data) {
    try {
        const response = await fetch(`${server_ip}/api/surveys`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data
            }),
        })
        return await response.json();
    } catch (error) {
        console.log(JSON.stringify(error));
        return { errno: 1, message: error?.message || 'unknown error' }
    }
}
export{
    PostSurvey,
}