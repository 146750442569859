import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      d="M20.593 2.203A2.506 2.506 0 0 0 18.831.437C17.265.007 11.001 0 11.001 0S4.735-.007 3.168.404a2.56 2.56 0 0 0-1.766 1.778C.99 3.748.986 6.996.986 6.996s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831ZM8.996 10.005l.005-6 5.207 3.005-5.212 2.995Z"
    />
  </svg>
)
export default SvgComponent
