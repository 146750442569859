import '../../screens/register.css'
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import DetailsButtonSvg from '../../svgs/DetailsButtonSvg'
const bgColor = {
    'Teachers': ['#2FC0E0','#0050C8'],
    'Artists': ['#0092C0','#2FC0E0'],
    'Patron':['#0050C8','#00193F']
}
const imageName = {
    'Teachers': 'rr1.png',
    'Artists': 'rr2.png',
    'Patron': 'rr3.png',
}
const noS = {
    'Teachers': 'Teacher',
    'Artists': 'Artist',
    'Patron': 'Patron',
}
function Screen({content, userData}) {
    return (
        <div className={'relative w-[336px] three-card-height text-[white] overflow-visible flex flex-col items-center select-none smaller-card ' + (noS[content.type] === userData.userType ? '' : 'not-selected')} style={{backgroundColor: bgColor[content.type][1], filter:`grayscale(${noS[content.type] === userData.userType ? 0 : 1})`}}>
            <div className='font-24-16 font-bold py-[20px]'>{content.type}</div>
            <div className={(noS[content.type] === userData.userType ? '' : 'three-card-hide')}>
                <img src={`/${imageName[content.type]}`}></img>
                <div className='px-[14px] mt-[28px]'>
                    {content.text.map((text) => (<div className='text-center font-12-10'>{text}</div>))} 
                </div>
            </div>
            <div className='w-[103%] absolute bottom-[-2px] left-[-.5px] flex justify-center items-center three-card-hide'>
                <DetailsButtonSvg color={bgColor[content.type][0]}/>
                <div className='absolute font-bold text-[18px] cursor-pointer hover:font-[900]'>{`Select`}</div>
            </div>
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
})(Screen)
  