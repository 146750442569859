import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';

import Hero from '../components/archives/Hero'
import Content from '../components/archives/Content'
import ParallaxBanner from '../components/ParallaxBanner'
import { useCookies } from 'react-cookie';
import { COOKIESPREFIX } from '../config';
import PopSurvey from '../components/archives/PopSurvey';

function Screen(props) {
    const [cookies, setCookie, removeCookie] = useCookies([`@${COOKIESPREFIX}_gensurvey`]);
    const [popSurvey, setPopSurvey] = useState(false)
    const handleSetPopSurvery = (bol) => setPopSurvey(bol)
    useEffect(() => {
        if(!cookies[`@${COOKIESPREFIX}_gensurvey`])setPopSurvey(true)
    },[])

    return (
        <div className='flex flex-col w-full text-[black] h-full bg-[#fff]'>
            <Hero handleSetRightNav={props.handleSetRightNav}/>
            <Content/>
            <ParallaxBanner srcollPostion={props.srcollPostion}/>
            {popSurvey && <PopSurvey handleSetPopSurvery={handleSetPopSurvery}/>}
        </div>
    );
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
  })(Screen)
  