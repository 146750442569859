import TopLeftCurve from '../../svgs/TopLeftCurve';
import TopRightCurve from '../../svgs/TopRightCurve';
export default function Component(props) {
    return (
      <div className='relative w-full flex flex-col text-black'>
        <TopLeftCurve className='absolute top-0 left-0'/>
        <TopRightCurve className='absolute top-0 right-0'/>
        <div className="relative w-full flex flex-col items-center justify-center d-px100-m-px24">
            <div className='max-w-[1200px] w-full flex d-row-m-col py-[200px] '>
                <div className="flex flex-col flex-1 pr-[10%] mb-[24px]">
                    <div className="text-[36px] w-full">About the Hong Kong Arts Festival</div>
                    <div className="text-[16px] w-full">Explore all the resources on our platform at your leisure.</div>
                    <div className="text-[16px] w-full mt-[24px]">Launched in 1973, the Hong Kong Arts Festival is a major international arts festival committed to enriching the cultural life of the city. In February and March every year, the Festival presents leading local and international artists in all genres of the performing arts, placing equal importance on great traditions and contemporary creations. The Festival also commissions and produces work in Cantonese opera, theatre, music, chamber opera and contemporary dance by Hong Kong’s own creative talent and emerging artists; many of these works have had successful subsequent runs in Hong Kong and overseas. </div>
                    <div className="text-[16px] w-full mt-[24px]">The Festival also presents over 250 “PLUS” and educational activities that offer diverse arts experiences to the community as well as tertiary, secondary and primary school students. In addition, through the “No Limits” project co-presented with The Hong Kong Jockey Club Charities Trust, the Festival strives to create an inclusive space for people with different abilities to share the joy of the arts together.</div>
                </div>
                <div className="flex flex-col w-[381px] max-w-[100%]">
                    <img src='/about2.png' className="w-full" alt="about"/>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  