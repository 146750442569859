import Navbar from "../Navbar"

export default function Component(props) {
    return (
      <div className='relative w-full text-white flex items-center justify-center' style={{}}>
          <div className='absolute w-full top-[0px] left-[0px] right-[0px] navbar-h text-white flex flex-row items-center justify-center' style={{zIndex:97}}>
            <div className='absolute w-full top-[0px] left-[0px] right-[0px] navbar-h text-white bg-[#ffffff10] flex flex-row items-center justify-between' style={{backdropFilter: 'blur(5px)'}} />
            <Navbar heroNav={true} handleSetRightNav={props.handleSetRightNav}/>
          </div>
          
          <img src='/hero4.png' style={{width:'100%',minHeight:400,objectFit:'cover'}} alt='hero'/>
          <div className="absolute w-full flex flex-col items-center left-0 justify-center d-px100-m-px24">
            <div className="max-w-[1200px] w-full flex flex-col">
                <div className="w-full text-[36px]">Programmes</div>
                <div className="w-full text-[#fff] text-[16px] mt-[24px]">Explore all the resources on our platform at your leisure.</div>
            </div>
          </div>
      </div>
    );
  }
  
  