import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    {...props}
  >
    <path stroke="#fff" strokeWidth={2} d="M0 1h19M0 9.444h19M0 17.889h19" />
  </svg>
)
export default SvgComponent
